let demoMode = false

if (import.meta.client) {
  const preferences = JSON.parse(localStorage.getItem("preferences"))
  if (preferences) {
    demoMode = preferences.demoMode === true;
  }
}

export function formatDateX(date: string): string {
  if (!date) return "-";
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return "-"
  }
  return d.toLocaleString("ro-RO", {
    dateStyle: "long",
    timeStyle: "long",
  });
}

export function formatDateL(date: string): string {
  if (!date) return "-";
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return "-"
  }
  return d.toLocaleString("ro-RO", {
    dateStyle: "long",
    timeStyle: "short",
  });
}

export function formatDateM(date: string): string {
  if (!date) return "-";
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return "-"
  }
  return d.toLocaleString("ro-RO", { dateStyle: "long" });
}

export function formatDateS(date: string): string {
  if (!date) return "-";
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return "-"
  }
  return d.toLocaleString("ro-RO", { dateStyle: "short" });
}

export function formatHours(hours: number): string {
  let h = Math.floor(hours);
  let m = Math.floor((hours - h) * 60);

  if (m < 10) {
    m = "0" + m;
  }

  return `${h}:${m}`;
  // return `${hours} `+ `${h}:${m}`;
}

export function formatAmount(amount: number) {
  let value = new Intl.NumberFormat("ro", {
    style: "currency",
    currency: "MDL",
    maximumFractionDigits: 0,
  }).format(amount);

  if (demoMode) {
    value = value.replace(/[0-9]/g, "*");
  }

  return value;
}

export function formatAmountM(quantity: number, amount: number) {
  let value = (
    quantity +
    " x " +
    new Intl.NumberFormat("ro", {
      style: "currency",
      currency: "MDL",
      maximumFractionDigits: 0,
    }).format(amount) +
    " = " +
    new Intl.NumberFormat("ro", {
      style: "currency",
      currency: "MDL",
      maximumFractionDigits: 0,
    }).format(quantity * amount)
  )

  if (demoMode) {
    value = value.replace(/[0-9]/g, "*");
  }

  return value;
}

export function formatQuantity(quantity: number) {
  return quantity.toFixed(0);
}

export function formatName(object: any) {
  let value = "";

  if (object.name) {
    value = object.name;
  }
  value = [object.first_name, object.last_name].join(" ");

  if (demoMode) {
    value = value.replace(/[A-Za-z]/g, "*");
  }

  return value;
}

export function formatPhone(object: any) {
  let value = "";

  if (object.prefix) {
    value = object.prefix;
  }
  value += ` ${object.phone}`

  if (demoMode) {
    value = value.replace(/[A-Za-z]/g, "*");
  }

  return value;
}

export function formatConfidence(confidence: number) {
  return (confidence * 100).toFixed(2) + "%";
}

export function formatReason(reasons: Array<string>) {
  const reasonText = (reason: string) => {
    if (reason === "multiple_accounts") {
      return "Mai multe conturi pe acelasi device"
    } else if (reason === "different_location") {
      return "Locatie diferita de la cea de checkin"
    }
  }

  let reasonTexts = [];
  for (let i = 0; i < reasons.length; i++) {
    reasonTexts.push(reasonText(reasons[i]));
  }
  return reasonTexts.join(", ");
}

export function paymentText(payment: number) {
  if (payment === 0) {
    return "Numerar la livrare";
  } else if (payment === 1) {
    return "Card la livrare";
  } else if (payment === 2) {
    return "Online cu cardul";
  }
}

export function deliveryText(delivery: number) {
  if (delivery === 1) {
    return "Ridicare personală";
  } else if (delivery === 2) {
    return "Livrare gratuită în Chișinău";
  } else if (delivery === 3) {
    return "Livrare 60 lei în Chișinău";
  } else if (delivery === 4) {
    return "Livrare înafara Chișinăului";
  } else if (delivery === 5) {
    return "Livrare curier";
  }
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function objHash(object: any) {
  let string = JSON.stringify(object);
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
      let code = string.charCodeAt(i);
      hash = ((hash<<5)-hash)+code;
      hash = hash & hash;
  }
  return hash;
}

export async function normalizedSearchItems(items: Array<any>, q: string) {
  if (!q) return items;

  return items.filter((item) => item.label.toLowerCase().replace(/\s/g, "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(q.toLowerCase().replace(/\s/g, "").normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
}
