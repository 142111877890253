<script setup lang="ts">
const store = useStore();
const colorMode = useColorMode();
const color = computed(() =>
  colorMode.value === "dark" ? "#18181b" : "white"
);

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "ro",
  },
});

useSeoMeta({
  title: "Platforma de administrare - alexagroup",
  description: "Platforma de administrare - alexagroup",
});

const socket = ref<WebSocket | null>(null);

if (import.meta.client) {
  store.init();

  socket.value = new WebSocket(wsUrl + "/server/status")

  socket.value.onmessage = function (event) {
    if (event.data === "lockdown") {
      window.location.reload();
      return
    }

    let data = JSON.parse(event.data);
    if (data.type === "online") {
      store.loadOnlineUsers(data.data);
    }
  };

  socket.value.onerror = function (event) {
    socket.value.close();
  };
}

watch(
  () => store.user,
  (user) => {
    if (user && user.id) {
      socket.value.send(JSON.stringify({
        type: "online",
        data: user.id,
      }));
    }
  }
);
</script>

<template>
  <div>
    <VitePwaManifest />

    <NuxtLayout />

    <UNotifications />
    <LazyUModals />
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(5px);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.2s;
}

.layout-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}

.layout-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
